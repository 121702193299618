<template>
  <v-container>
    <v-card class="mx-auto" max-width="900" outlined color="transparent">
      <v-card-text>
        <div class="mt-10 text--primary text-h5">
          <span>문의 및 고민나눔</span>
        </div>
        <v-divider></v-divider>
        <br />
        <div class="d-flex justify-center">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSf1COj7yleMwK4X6DjGXKzCZTa1D-_xVyhALw5Q1LeXfZSrvQ/viewform?embedded=true"
            width="700rem"
            height="900rem"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            >로드 중…</iframe
          >
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Introduce',
  data() {
    return {
      isEnglish: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.circle-image {
  border-radius: 100%;
}
ul {
  list-style: none;
}
ul li::before {
  content: '\2022';
  color: #4caf50;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
* {
  line-height: 2;
}
</style>
